import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import * as recipientApi from "../../api/platform/recipientApi"
import ProductSelectionType from "../gift/choice/ProductSelectionType"
import GiftSelection from "./GiftSelection"
import { isNoAddressProduct } from "../../util/productUtil"
import SelectionPageConfigType from "../../model/SelectionPageConfigType"
import {
  AddressForm,
  DEFAULT_CONTACT_DETAILS_STATE,
  MetaForm,
  PhoneField,
  ThemeContext,
} from "@social-supermarket/social-supermarket-components"
import {
  AddressType,
  colors,
  ContactDetailsType,
  DEFAULT_ADDRESS_STATE,
  ThemeType,
} from "@social-supermarket/social-supermarket-model"

const DEFAULT_CONFIG: SelectionPageConfigType = {
  key: "",
  title: "",
  description: "",
  metaFields: [],
  isMultiRecipient: false,
  options: [],
  logoSrc: "",
  bannerSrc: "",
  theme: {},
  allowsOpenSelection: true,
  categories: [],
}

interface Props {
  responseKey?: string
}

const GiftSelectionPage: FC<Props> = ({ responseKey }) => {
  const [config, setConfig] = useState<SelectionPageConfigType>({ ...DEFAULT_CONFIG })
  const [metaValues, setMetaValues] = useState<{ [key: string]: string }>({})
  const [selectedItem, setSelected] = useState<ProductSelectionType>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [addressFormState, setAddressFormState] = useState<AddressType>({
    ...DEFAULT_ADDRESS_STATE,
  })
  const [contactFormState, setContactFormState] = useState<ContactDetailsType>({
    ...DEFAULT_CONTACT_DETAILS_STATE,
  })
  const [validate, setValidate] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const { theme, setTheme } = useContext(ThemeContext)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setIsLoading(true)
    try {
      const response = await recipientApi.getPrivateOptions(responseKey)
      if (response.status === "SUCCESS") {
        const page = response.data
        setConfig({ ...page, allowsOpenSelection: true })
        const savedTheme: ThemeType = page.theme ? page.theme : {}
        const theTheme: ThemeType = { ...theme, controls: colors.primaryLight, ...savedTheme }
        setTheme(theTheme)
      } else if (response.status === "ALREADY_SUBMITTED") {
        setSuccess(true)
      } else {
        console.log(`Unexpected error, please contact support: ${response.message}`)
      }
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    }
    setIsLoading(false)
  }

  const isValid = () => {
    return (
      (isNoAddressProduct(selectedItem.product) || AddressForm.isValid(addressFormState)) &&
      PhoneField.isValid(contactFormState) &&
      MetaForm.isValid(metaValues, config.metaFields)
    )
  }

  const handleSubmit = async () => {
    if (isValid()) {
      try {
        setIsSubmitting(true)
        await recipientApi.updatePrivateRecipient(
          responseKey,
          contactFormState.phone,
          {
            productId: selectedItem.product.productId,
            variantId: selectedItem.variant?.variantId,
          },
          addressFormState,
          metaValues
        )
        setSuccess(true)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      } catch (e) {
        console.log(`Unexpected error, please contact support: ${e}`)
      } finally {
        setIsSubmitting(false)
      }
    } else {
      setValidate(true)
    }
  }

  return (
    <GiftSelection
      config={config}
      selectedItem={selectedItem}
      setSelectedItem={item => setSelected(item)}
      address={addressFormState}
      setAddress={setAddressFormState}
      contactDetails={contactFormState}
      setContactDetails={setContactFormState}
      validate={validate}
      success={success}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      metaValues={metaValues}
      setMetaValues={setMetaValues}
    />
  )
}

export default GiftSelectionPage
