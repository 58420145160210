import * as React from "react"
import GiftSelectionPage from "../social-supermarket/pages/gift-selection/GiftSelectionPage"
import { Router } from "@reach/router"

const GiftSelectionTemplate = () => {
  return (
    <div>
      <Router>
        <GiftSelectionPage path="select-gift/:responseKey" />
      </Router>
    </div>
  )
}

export default GiftSelectionTemplate
